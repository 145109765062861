@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--matterColorBright);

  /* sticky result summary in mobile */
  position: sticky;
  top: 0;
  z-index: 1;
}

.searchResultSummary {
  composes: marketplaceH3FontStyles from global;
  margin-top: 6px;

  display: flex;
  justify-content: space-between;

  background-color: var(--matterColorBright);

  @media (--viewportMedium) {
    margin-top: 8px;
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;

  & .filters {
    display: flex;
    align-items: center;
    width: 82%;

    & .itemSlider {
      width: 100%;

      & .sliderItem {
        /* padding: 0 10px; */
      }
    }

    & .sliderControls {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: var(--marketplaceColor) !important;
      color: var(--matterColorLight) !important;
      padding: 0;
      margin: 0;
      height: 3rem;
      width: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (--viewportMedium) {
        height: 3rem;
        width: 3rem;
      }

      @media (--viewportLarge) {
        height: 3rem;
        width: 6rem;
      }

      &:focus {
        outline: none;
      }

      &:hover {
        cursor: pointer;

        & > svg {
          & > path {
            fill: var(--matterColorLight);
          }
        }
      }

      &.leftArrow {
        left: 10px;
        background-color: var(--matterColorAnti) !important;
        width: 24px;
        height: 24px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        border-radius: 100px !important;
        margin: 0 !important;
        border: none;
        cursor: pointer;

        &:hover {
          background-color: var(--marketplaceColor) !important;
        }

        & > svg {
          width: 14px;
          height: 11px;
        }
      }

      &.rightArrow {
        right: 10px;
        background-color: var(--matterColorAnti) !important;
        width: 24px;
        height: 24px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        border-radius: 100px !important;
        margin: 0 !important;
        border: none;
        cursor: pointer;

        &:hover {
          background-color: var(--marketplaceColor) !important;
        }

        & > svg {
          width: 14px;
          height: 11px;
        }
      }
    }

    & :global(.react-multi-carousel-list) {
      display: block;
      width: 100%;
      overflow: hidden;
      position: relative;
      padding: 0px 50px;
    }

    & :global(.react-multi-carousel-track) {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      position: relative;
      transform-style: preserve-3d;
      backface-visibility: hidden;
      will-change: transform, transition;
    }

    & .link {
      text-decoration: none;
      position: relative;
      transition: all ease 0.5s;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 10px 0;

      &::after {
        content: '';
        bottom: 0px;
        left: 0;
        right: 0;
        width: 0;
        margin: 0 auto;
        position: absolute;
        background-color: var(--matterColorAnti);
        height: 2px;
        transition: all ease 0.5s;
      }

      &:hover {
        transition: all ease 0.5s;

        &::after {
          width: 80%;
          transition: all ease 0.5s;
          background-color: var(--matterColorDark);
        }

        & .quickFilter {
          & span {
            color: var(--matterColorDark);
          }

          & > svg {
            width: 24px;
            height: 24px;
            & path {
              fill: var(--matterColorDark);
            }

            & polygon {
              transition: all ease 0.5s;
              fill: var(--matterColorDark);
            }
          }
        }
      }

      &.active {
        transition: all ease 0.5s;

        &::after {
          width: 80%;
          background-color: var(--matterColorDark);
          transition: all ease 0.5s;
        }

        & .quickFilter {
          transition: all ease 0.5s;

          & span {
            transition: all ease 0.5s;
            color: var(--matterColorDark);
          }

          & > svg {
            width: 24px;
            height: 24px;
            transition: all ease 0.5s;

            & path {
              transition: all ease 0.5s;
              fill: var(--matterColorDark);
            }

            & polygon {
              transition: all ease 0.5s;
              fill: var(--matterColorDark);
            }
          }
        }
      }

      & .quickFilter {
        transition: all ease 0.5s;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;

        & img {
          width: 24px;
          margin-bottom: 5px;
        }

        & > svg {
          width: 24px;
          height: 24px;
          margin-bottom: 5px;
          transition: all ease 0.5s;

          & path {
            transition: all ease 0.5s;
            fill: var(--matterColorAnti);
          }

          & polygon {
            transition: all ease 0.5s;
            fill: var(--matterColorAnti);
          }
        }

        & span {
          color: var(--matterColorAnti);
          font-size: 13px;
          line-height: 116%;
          transition: all ease 0.5s;
          text-align: center;
        }
      }
    }

    & .filterSearchBox {
      width: calc(100% - 110px);
    }
  }
}

.filtersButton {
  composes: marketplaceButtonStylesSecondary from global;
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Avoid stretching button width. */
  flex-basis: 0;

  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0 9px 0 0;
  border-radius: 4px;
}

.filtersButtonSelected {
  composes: marketplaceButtonStyles from global;
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Avoid stretching button width. */
  flex-basis: 0;

  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0 9px 0 0;
  border-radius: 4px;
}

.mapIcon {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* background map image */
  background-image: url(./images/map_icon216x105.png);
  background-size: cover;

  border-radius: 3px;
  width: 72px;
  height: 35px;
  padding: 9px 22px;
  margin: 0;
}

.modalContainer {
  width: 100%;
  padding: 94px 0;
  margin: 0 24px;
}

.modalHeadingWrapper {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--matterColorNegative);
  margin-bottom: 20px;
}

.modalHeading {
  composes: marketplaceH1FontStyles from global;
  margin-top: 0;
}

.resetAllButton {
  composes: marketplaceH5FontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  display: inline;
  float: right;
  margin: 8px 0 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }
}

.filtersWrapper {
  /* add bottom margin so that the last filter won't be hidden
   * under the mobile safari bottom bar or the "Show results"
   * button bar */
  padding-bottom: 220px;

  & > * {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
}

.showListingsContainer {
  position: fixed;
  bottom: 50px;
  left: 0;

  width: 100vw;
  padding: 18px 24px;

  background: var(--matterColorLight);
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 11;
}

.showListingsButton {
  background-color: var(--marketplaceColor);
  display: inline-block;
  border-radius: 3px;
}

.mobileSearchFilterBar {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  background-color: var(--matterColorLight);
  border: solid 1px var(--matterColorNegative);
  box-shadow: 0 3px 10px rgb(0 0 0 / 10%);
  border-radius: 100px;
  padding: 10px;
  margin: 10px 0;

  & .sortyByWrapper {
    display: flex;
    align-items: center;

    & > * {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    & .moreFilters {
      border: solid 1px var(--matterColorNegative);
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      padding: 0px;
      height: 35px;
      width: 35px;
      color: var(--matterColorDark);
      cursor: pointer;
      transition: all ease 0.5s;
      position: relative;
      &:hover {
        transition: all ease 0.5s;
        color: var(--matterColorDark);
        border-color: var(--matterColorAnti);
        box-shadow: var(--boxShadowButton);

        & > svg {
          transition: all ease 0.5s;

          & > path {
            transition: all ease 0.5s;
            stroke: var(--matterColorDark);
          }
        }
      }

      & .count {
        width: 20px;
        height: 20px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        font-size: 13px;
        color: var(--matterColorLight);
        font-weight: var(--fontWeightRegular);
        position: absolute;
        top: -6px;
        right: -6px;
        background-color: var(--marketplaceColor);
      }
      & > svg {
        transition: all ease 0.5s;
        height: 18px;
        width: 18px;

        & > path {
          transition: all ease 0.5s;
          stroke: var(--matterColorDark);
        }
      }
    }
  }
}
