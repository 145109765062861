@import '../../../styles/customMediaQueries.css';

.root {
}

.icon {
  position: relative;
  margin-left: 12px;
  transition: all 0.2s ease-out;

  @media (--viewportMedium) {
    margin-left: 36px;
  }
}

.iconArrowAnimation {
  transform: rotate(-180deg);
}

.menuLabel {
  /* composes: marketplaceButtonStylesSecondary from global;
  composes: marketplaceSearchFilterLabelFontStyles from global; */

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  min-height: 0;
  border-radius: 10px !important;
  font-size: 14px;
  line-height: 20px;
  color: var(--matterColorDark);
  min-width: 111px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  transition: all ease 0.5s;
  border: solid 1px var(--matterColorNegative);

  &:focus,
  &:hover {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    transition: all ease 0.5s;
    color: var(--matterColorDark);
    border-color: var(--matterColorAnti);
    box-shadow: var(--boxShadowButton);
  }

  & > svg {
    margin-right: 5px;
  }
}

.menuContent {
  margin-top: 7px;
  padding-top: 24px;
  padding-bottom: 24px;
  min-width: 200px;
  border-radius: 4px;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  background-color: var(--marketplaceColor);
  transition: width var(--transitionStyleButton);
}

/* left static border for selected element */
.menuItemBorderSelected {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 7px);
  width: 6px;
  background-color: var(--matterColorDark);
}

.menuHeading {
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColor);
  margin-top: 24px;
  margin-left: 30px;
  margin-right: 30px;
}

.menuItem {
  composes: marketplaceListingAttributeFontStyles from global;
  color: var(--matterColor);
  font-size: 14px;
  position: relative;
  min-width: 200px;
  margin: 0;
  padding: 4px 30px;
  transition: all ease 0.5s;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColorDark);
    transition: all ease 0.5s;
  }

  &:hover .menuItemBorder {
    width: 6px;
  }

  &:disabled {
    color: var(--matterColorAnti);
    cursor: default;
  }

  &:disabled:hover .menuItemBorder {
    width: 0;
  }
}

.clearMenuItem {
  composes: marketplaceH4FontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  position: relative;
  min-width: 200px;
  margin: 0;
  padding: 32px 30px 18px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  cursor: pointer;
  transition: width var(--transitionStyleButton);

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}

.sortingType {
  display: none;

  @media (--viewportLarge) {
    display: inline-block;
  }
}
