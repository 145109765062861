@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.listingCardsMapVariant {
  padding: 0 0 96px 0;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  width: 100%;

  @media (--viewportLarge) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportXLarge) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.listingCards {
  & .productLoaders {
    padding: 0 0 72px 0;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    width: 100%;

    @media (min-width: 550px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (--viewportMedium) {
      padding: 0 0 96px 0;
      grid-template-columns: repeat(3, 1fr);
    }

    @media (--viewportLarge) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (min-width: 1440px) {
      /* grid-template-columns: repeat(5, 1fr); */
    }

    @media (--viewportXLarge) {
      /* grid-template-columns: repeat(5, 1fr); */
    }
  }

  & > div {
    & > div {
      padding: 0 0 72px 0;

      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;
      width: 100%;

      @media (min-width: 550px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (--viewportMedium) {
        padding: 0 0 96px 0;
        grid-template-columns: repeat(3, 1fr);
      }

      @media (--viewportLarge) {
        grid-template-columns: repeat(4, 1fr);
      }

      @media (min-width: 1440px) {
        /* grid-template-columns: repeat(5, 1fr); */
      }

      @media (--viewportXLarge) {
        /* grid-template-columns: repeat(5, 1fr); */
      }
    }
  }
}

.listingCard {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;

  @media (--viewportMedium) {
    border: solid 1px #f3f3f3;
    background-color: var(--matterColorLight);
  }
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color: transparent;
}
