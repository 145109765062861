@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: inline-block;
}

.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;
}

.fieldGroupPlain {
  margin: 0;
  padding: 0;
  border: none;
}

.list {
  margin: 0;
  max-height: 300px;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;

  &::-webkit-scrollbar {
    display: none;
  }
}

.item {
  padding: 2px 0;
  width: 100%;
  margin-bottom: 5px;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportSmall) {
    width: calc(100% / 2);
  }

  @media (--viewportMedium) {
    padding: 4px 0;
  }
  &.colorItem {
    display: flex;
    align-items: center;
    & > label {
      & > img {
        cursor: pointer;
        width: 30px;
        height: 30px;
        border: solid 2px var(--matterColorAnti);
        border-radius: 100px;
        padding: 1px;
      }
    }
    & > span {
      margin-left: 12px;
      & > label {
        margin: 0;
        padding: 0;
        & > span {
          margin: 0;
          padding: 0;
          &:first-child {
            display: none;
          }
        }
      }
    }
  }
}

.showMore {
  color: var(--matterColorAnti);

  &:hover {
    color: var(--matterColorDark);
    cursor: pointer;
    text-decoration: underline;
  }
}

.showLess {
  color: var(--matterColorDark);
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: var(--matterColorAnti);
  }
}

.activeColorItem {
  cursor: pointer;
  & > label {
    & > img {
      border: solid 2px var(--marketplaceColor) !important;
    }
  }
}
